// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commande-js": () => import("./../../../src/pages/commande.js" /* webpackChunkName: "component---src-pages-commande-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-templates-boisson-page-js": () => import("./../../../src/templates/BoissonPage.js" /* webpackChunkName: "component---src-templates-boisson-page-js" */),
  "component---src-templates-dessert-page-js": () => import("./../../../src/templates/DessertPage.js" /* webpackChunkName: "component---src-templates-dessert-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/LegalPage.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-pain-page-js": () => import("./../../../src/templates/PainPage.js" /* webpackChunkName: "component---src-templates-pain-page-js" */),
  "component---src-templates-pate-page-js": () => import("./../../../src/templates/PatePage.js" /* webpackChunkName: "component---src-templates-pate-page-js" */),
  "component---src-templates-pate-page-option-js": () => import("./../../../src/templates/PatePageOption.js" /* webpackChunkName: "component---src-templates-pate-page-option-js" */),
  "component---src-templates-pizza-page-js": () => import("./../../../src/templates/PizzaPage.js" /* webpackChunkName: "component---src-templates-pizza-page-js" */),
  "component---src-templates-pizza-page-option-js": () => import("./../../../src/templates/PizzaPageOption.js" /* webpackChunkName: "component---src-templates-pizza-page-option-js" */),
  "component---src-templates-salade-page-js": () => import("./../../../src/templates/SaladePage.js" /* webpackChunkName: "component---src-templates-salade-page-js" */),
  "component---src-templates-snacking-page-js": () => import("./../../../src/templates/SnackingPage.js" /* webpackChunkName: "component---src-templates-snacking-page-js" */),
  "component---src-templates-snacking-page-option-js": () => import("./../../../src/templates/SnackingPageOption.js" /* webpackChunkName: "component---src-templates-snacking-page-option-js" */)
}

