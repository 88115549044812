import React from 'react'
//components
import LegalMenu from "../components/Menu/MenuLegal"
//css
import "../css/footer.scss"
const Footer = () => {
 return (
  <div className="footerWrapper"><div className="footer container">
   <LegalMenu/>
  </div></div>
  
 )
}

export default Footer
